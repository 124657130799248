import * as React from "react";

export const flutter_logo = {
  shape: (
    <React.Fragment>
      <path
        strokeWidth="7px"
        d="M135.07,2.82l81.67-.09-137,137L44,175.19,3.38,134.51Zm-.76,122.28c1.06-1.4,3-.71,4.49-.89l77.87,0-71,70.9-40.84-40.59Z"
        transform="translate(-1.62 -1.48)"
      />
      <path
        strokeWidth="7px"
        d="M64.15,195.2l40.71-40.66,40.84,40.59.17.18-41,40.62L64.15,195.2Z"
        transform="translate(-1.62 -1.48)"
      />
      <path
        strokeWidth="7px"
        d="M104.87,235.93l41-40.62,70.83,70.9c-26.68.06-53.35,0-80,0-1.52.34-2.46-1.05-3.43-1.91l-28.38-28.41Z"
        transform="translate(-1.62 -1.48)"
      />
    </React.Fragment>
  ),
  viewBox: `-3 -3 465 375`,
  strokeWidth: "5px",
};
