import * as React from "react";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import AboutMDX from "../sections/about";
import { SvgIconGithub, SvgOpenLink } from "./icon_button_svg";

const videoHtml = ` <video width="215" height="464" autoPlay muted loop playsinline>
<source src="video/taxi_demo_video.mp4" type="video/mp4" />
</video> `;

const About = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider
      bg="divider"
      clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
      speed={0.2}
      offset={offset}
      factor={factor}
    />
    <Divider speed={0.1} offset={offset} factor={factor}>
      {/*
        
        !!!!!!!!!!!!!!!!!!!
        ICONS GO HERE!!!!!!!
        !!!!!!!!!!
        */}
    </Divider>
    <Content speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <h2 className="fbs-h2">We build apps in record time</h2>
        <p className="fbs-subtitle">
          Our Flutter Taxi app built from scratch in just 30 hours of coding.
        </p>
        <div style={{ height: "30px" }}></div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative", width: "214px" }}>
            <div
              className="rc"
              dangerouslySetInnerHTML={{ __html: videoHtml }}
            ></div>

            <div
              style={{
                position: "absolute",
                top: -16,
                left: -18,
                zIndex: 500,
              }}
            >
              <img src="img/frame.png" alt="iphone frame" />
            </div>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <SvgOpenLink />
          <div
            className="fbs-body"
            style={{
              paddingLeft: "5px",
            }}
          >
            <a href="#" className="fbs-a-body">
              Online Demo
            </a>
          </div>

          <div style={{ width: "30px" }}></div>
          <SvgIconGithub />
          <div
            className="fbs-body"
            style={{
              paddingLeft: "5px",
            }}
          >
            <a href="#" className="fbs-a-body">
              Source Code
            </a>
          </div>
        </div>
      </Inner>
    </Content>
  </div>
);

export default About;
