/** @jsx jsx */
import { jsx } from "theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import ProjectsMDX from "../sections/projects";
import ProjectCard from "./project-card";

const videoHtmlVbb = `<video style="width:100%; height:100%;max-width:600px; max-height:750px" autoPlay muted loop playsinline>
<source src="video/vbb_demo.mp4" type="video/mp4" />
</video>`;

const videoHtmlRic = `<video style="width:100%; height:100%;max-width:600; max-height:750px" autoPlay muted loop playsinline>
<source src="video/ric_demo.mp4" type="video/mp4" />
</video> `;

const Projects = ({
  offset,
  factor = 2,
}: {
  offset: number;
  factor?: number;
}) => (
  <div>
    <Divider
      bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
      sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
      speed={-0.2}
      offset={offset + 0.1}
      factor={factor}
    />
    <Content speed={0.4} offset={offset + 0.2} factor={factor}>
      <Inner>
        <div
          sx={{
            display: `grid`,
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
            h2: { gridColumn: `-1/1`, color: `white !important` },
          }}
        >
          <h2 className="fbs-h2">Startups we already helped</h2>
          <ProjectCard
            title="GDPR complient"
            link="https://www.lekoarts.de/gatsby/tips-and-tricks-for-gatsby?utm_source=cara&utm_medium=Theme"
            bg="#D4145A"
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <div
                  className="vd"
                  dangerouslySetInnerHTML={{ __html: videoHtmlVbb }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: 2,
                    left: 2,
                    bottom: 3,
                    right: 3,
                    zIndex: 500,
                    border: "solid",
                    opacity: "0.5",
                    borderColor: "#D4145A",
                    borderRadius: "10px",
                    borderWidth: "2px",
                    boxShadow: "0 0 0 10px #D4145A",

                    // width: "100%",
                    //height: "100%",
                    // maxWidth: "384px",
                    //maxHeight: "480px",
                  }}
                ></div>
              </div>
            </div>
            Cloud video recorder with AI enabled face and car licence
            obsflucation
          </ProjectCard>
          <ProjectCard
            title="Voice controlled"
            link="https://www.lekoarts.de/javascript/setting-up-a-yarn-workspace-with-typescript-eslint-and-cypress?utm_source=cara&utm_medium=Theme"
            bg="#662D8C"
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <div
                  className="rc"
                  dangerouslySetInnerHTML={{ __html: videoHtmlRic }}
                ></div>

                <div
                  style={{
                    position: "absolute",
                    top: 2,
                    left: 2,
                    bottom: 3,
                    right: 3,
                    zIndex: 500,
                    border: "solid",
                    opacity: "0.5",
                    borderColor: "#662D8C",
                    borderRadius: "10px",
                    borderWidth: "2px",
                    boxShadow: "0 0 0 10px #662D8C",

                    // width: "100%",
                    //height: "100%",
                    // maxWidth: "384px",
                    //maxHeight: "480px",
                  }}
                ></div>
              </div>
            </div>
            Automatic Seismic Risc Analisys application for Italy with full
            voice control feature
          </ProjectCard>
        </div>
      </Inner>
    </Content>
    <Divider speed={0.1} offset={offset} factor={factor}>
      {
        /*
        
        !!!!!!!!!!!!!!!!!!!
        ICONS GO HERE!!!!!!!
        !!!!!!!!!!
        */
     }
    </Divider>
  </div>
);

export default Projects;
