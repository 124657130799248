/** @jsx jsx */
import { jsx, ThemeProvider, Heading } from "theme-ui";
import { Themed } from "theme-ui";
// @ts-ignore
import theme from "../gatsby-plugin-theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
// @ts-ignore
import Intro from "../sections/intro";
import * as st from "../gatsby-plugin-theme-ui/st";
//import "../app.css";

const videoHtml = ` <video width="215" height="464" autoPlay muted loop playsinline>
<source src="video/taxi_demo_video.mp4" type="video/mp4" />
</video> `;

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0.2} offset={offset} factor={factor}>
      {/************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/}

      <UpDown>
        <Svg
          icon="so_cloud_arrow"
          hiddenMobile
          width={48}
          stroke
          color="icon_darker"
          left="10%"
          top="5%"
        />
        <Svg
          icon="flutter_logo"
          width={48}
          stroke
          color="#46B7EA"
          left="60%"
          top="70%"
        />

        <Svg
          icon="so_docker"
          width={110}
          //stroke
          color="icon_darkest"
          left="30%"
          top="60%"
        />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="so_db"
          width={12}
          //stroke
          color="icon_darkest"
          left="75%"
          top="10%"
        />

        <Svg
          icon="so_vr"
          width={12}
          //stroke
          color="icon_darker"
          left="85%"
          top="50%"
        />
        <Svg
          icon="s_bitcoin"
          width={16}
          stroke
          color="icon_darkest"
          left="38%"
          top="15%"
        />
      </UpDownWide>

      {/************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/
      /************************************************************/}
    </Divider>
    <Content
      sx={{ variant: `texts.bigger` }}
      speed={0.4}
      offset={offset}
      factor={factor}
    >
      <Inner>
        <h1 className="fbs-h1">We are Flutterbase</h1>
        <div className="fbs-subtitle">
          Turn your startup idea to reality with Google Flutter and Cloud Native
          technology professionals.
        </div>
      </Inner>
    </Content>
  </div>
);

export default Hero;
