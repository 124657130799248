import * as React from "react";

export const so_vr = {
  shape: (
    <React.Fragment>
      <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
      <path d="M4.508 11h1.429l1.99-5.999H6.61L5.277 9.708H5.22L3.875 5.001H2.5L4.508 11zm6.387-5.999H8.5V11h1.173V8.763h1.064L11.787 11h1.327L11.91 8.583C12.455 8.373 13 7.779 13 6.9c0-1.147-.773-1.9-2.105-1.9zm-1.222 2.87V5.933h1.05c.63 0 1.05.347 1.05.989 0 .633-.408.95-1.067.95H9.673z" />
    </React.Fragment>
  ),
  viewBox: `0 0 16 16`,
  strokeWidth: "1px",
};

export const so_db = {
  shape: (
    <React.Fragment>
      <path
        d="M55.232,55.479l-5.969-6.243c1.745-1.918,2.82-4.458,2.82-7.25c0-5.953-4.843-10.796-10.796-10.796
		s-10.796,4.843-10.796,10.796s4.843,10.796,10.796,10.796c2.442,0,4.689-0.824,6.5-2.196l6,6.276
		c0.196,0.205,0.459,0.309,0.723,0.309c0.249,0,0.497-0.092,0.691-0.277C55.6,56.511,55.614,55.878,55.232,55.479z M32.491,41.985
		c0-4.85,3.946-8.796,8.796-8.796s8.796,3.946,8.796,8.796s-3.946,8.796-8.796,8.796S32.491,46.835,32.491,41.985z"
      />
      <path
        d="M26.66,41c-0.827,0-1.637-0.016-2.432-0.044c-0.486-0.018-0.955-0.049-1.429-0.077c-0.293-0.017-0.595-0.028-0.883-0.049
		c-0.63-0.045-1.242-0.104-1.847-0.167c-0.114-0.012-0.233-0.02-0.346-0.032c-0.693-0.076-1.368-0.163-2.026-0.259
		c-0.017-0.002-0.034-0.004-0.051-0.007c-3.385-0.5-6.29-1.263-8.561-2.169c-0.012-0.004-0.024-0.009-0.036-0.014
		c-0.419-0.168-0.812-0.342-1.186-0.519c-0.043-0.021-0.089-0.041-0.132-0.062c-0.336-0.162-0.647-0.328-0.945-0.497
		c-0.07-0.04-0.144-0.079-0.212-0.12c-0.259-0.152-0.495-0.307-0.721-0.463c-0.086-0.06-0.175-0.12-0.257-0.18
		c-0.191-0.141-0.362-0.283-0.526-0.426c-0.089-0.079-0.178-0.157-0.26-0.236c-0.134-0.13-0.252-0.261-0.363-0.392
		c-0.078-0.092-0.153-0.185-0.219-0.277c-0.088-0.123-0.163-0.246-0.23-0.369c-0.054-0.099-0.102-0.198-0.143-0.297
		c-0.049-0.121-0.088-0.242-0.116-0.362C3.701,33.823,3.66,33.661,3.66,33.5v-8.207c0.028,0.026,0.063,0.051,0.092,0.077
		c0.219,0.191,0.44,0.382,0.69,0.566C8.209,28.786,15.742,31,26.66,31c10.873,0,18.386-2.196,22.169-5.028
		c0.302-0.22,0.574-0.447,0.83-0.678c0,0,0,0,0,0l0,4.707c0,0.553,0.447,1,1,1s1-0.447,1-1v-8v-0.5v-12V9
		c0-0.182-0.062-0.343-0.146-0.49C50.357,4.22,41.737,0,26.66,0C11.583,0,2.964,4.22,1.807,8.51C1.722,8.657,1.66,8.818,1.66,9v0.5
		v12V22v11.5V34v12c0,0.161,0.042,0.313,0.115,0.448c1.139,4.833,10.691,7.68,19.81,8.364c0.025,0.002,0.051,0.003,0.076,0.003
		c0.519,0,0.957-0.399,0.996-0.925c0.041-0.551-0.371-1.031-0.922-1.072C10.621,51.984,4.145,48.6,3.692,45.838
		c-0.008-0.045-0.019-0.088-0.032-0.131v-8.414c0.028,0.026,0.063,0.051,0.092,0.077c0.219,0.191,0.44,0.382,0.69,0.566
		C8.209,40.786,15.742,43,26.66,43c0.553,0,1-0.447,1-1S27.213,41,26.66,41z M4.262,13.797c0.3,0.236,0.624,0.469,0.975,0.696
		c0.073,0.047,0.155,0.093,0.231,0.14c0.294,0.183,0.605,0.362,0.932,0.538c0.121,0.065,0.242,0.129,0.368,0.193
		c0.365,0.186,0.748,0.366,1.151,0.542c0.066,0.029,0.126,0.059,0.192,0.087c0.469,0.199,0.967,0.389,1.486,0.572
		c0.143,0.051,0.293,0.099,0.44,0.149c0.412,0.139,0.838,0.272,1.279,0.401c0.159,0.046,0.315,0.094,0.478,0.139
		c0.585,0.162,1.189,0.316,1.823,0.458c0.087,0.02,0.181,0.037,0.269,0.056c0.559,0.122,1.139,0.235,1.735,0.34
		c0.202,0.036,0.407,0.07,0.613,0.104c0.567,0.093,1.151,0.179,1.75,0.257c0.154,0.02,0.302,0.042,0.457,0.062
		c0.744,0.09,1.513,0.167,2.305,0.233c0.195,0.016,0.398,0.028,0.596,0.042c0.633,0.046,1.28,0.084,1.942,0.114
		c0.241,0.011,0.481,0.022,0.727,0.03c0.863,0.03,1.74,0.05,2.65,0.05s1.788-0.021,2.65-0.05c0.245-0.008,0.485-0.02,0.727-0.03
		c0.662-0.03,1.309-0.068,1.942-0.114c0.198-0.015,0.401-0.026,0.596-0.042c0.791-0.065,1.561-0.143,2.305-0.233
		c0.156-0.019,0.303-0.042,0.457-0.062c0.599-0.078,1.182-0.164,1.75-0.257c0.206-0.034,0.411-0.068,0.613-0.104
		c0.596-0.105,1.176-0.218,1.735-0.34c0.088-0.019,0.182-0.036,0.269-0.056c0.634-0.142,1.238-0.296,1.823-0.458
		c0.163-0.045,0.319-0.092,0.478-0.139c0.441-0.128,0.867-0.262,1.279-0.401c0.147-0.05,0.297-0.098,0.44-0.149
		c0.518-0.184,1.017-0.374,1.486-0.572c0.067-0.028,0.127-0.059,0.192-0.087c0.403-0.176,0.786-0.356,1.151-0.542
		c0.125-0.064,0.247-0.128,0.368-0.193c0.327-0.175,0.638-0.354,0.932-0.538c0.076-0.047,0.158-0.093,0.231-0.14
		c0.351-0.227,0.675-0.459,0.975-0.696c0.075-0.06,0.142-0.12,0.214-0.18c0.13-0.108,0.267-0.215,0.387-0.324V21.5
		c0,0.161-0.041,0.323-0.079,0.485c-0.028,0.121-0.067,0.241-0.116,0.362c-0.04,0.099-0.089,0.198-0.143,0.297
		c-0.067,0.123-0.142,0.246-0.23,0.369c-0.066,0.092-0.141,0.185-0.219,0.277c-0.111,0.131-0.229,0.262-0.363,0.392
		c-0.081,0.079-0.17,0.157-0.26,0.236c-0.164,0.143-0.335,0.285-0.526,0.426c-0.082,0.061-0.17,0.12-0.257,0.18
		c-0.226,0.156-0.462,0.311-0.721,0.463c-0.068,0.041-0.141,0.08-0.212,0.12c-0.297,0.168-0.609,0.334-0.945,0.497
		c-0.043,0.021-0.088,0.041-0.132,0.062c-0.375,0.177-0.767,0.351-1.186,0.519c-0.012,0.005-0.024,0.009-0.036,0.014
		c-2.271,0.907-5.176,1.67-8.561,2.169c-0.017,0.002-0.034,0.004-0.051,0.007c-0.658,0.097-1.333,0.184-2.026,0.259
		c-0.113,0.012-0.232,0.02-0.346,0.032c-0.605,0.063-1.218,0.121-1.847,0.167c-0.288,0.021-0.59,0.032-0.883,0.049
		c-0.474,0.028-0.943,0.059-1.429,0.077C28.297,28.984,27.487,29,26.66,29s-1.637-0.016-2.432-0.044
		c-0.486-0.018-0.955-0.049-1.429-0.077c-0.293-0.017-0.595-0.028-0.883-0.049c-0.63-0.045-1.242-0.104-1.847-0.167
		c-0.114-0.012-0.233-0.02-0.346-0.032c-0.693-0.076-1.368-0.163-2.026-0.259c-0.017-0.002-0.034-0.004-0.051-0.007
		c-3.385-0.5-6.29-1.263-8.561-2.169c-0.012-0.004-0.024-0.009-0.036-0.014c-0.419-0.168-0.812-0.342-1.186-0.519
		c-0.043-0.021-0.089-0.041-0.132-0.062c-0.336-0.162-0.647-0.328-0.945-0.497c-0.07-0.04-0.144-0.079-0.212-0.12
		c-0.259-0.152-0.495-0.307-0.721-0.463c-0.086-0.06-0.175-0.12-0.257-0.18c-0.191-0.141-0.362-0.283-0.526-0.426
		c-0.089-0.079-0.178-0.157-0.26-0.236c-0.134-0.13-0.252-0.261-0.363-0.392c-0.078-0.092-0.153-0.185-0.219-0.277
		c-0.088-0.123-0.163-0.246-0.23-0.369c-0.054-0.099-0.102-0.198-0.143-0.297c-0.049-0.121-0.088-0.242-0.116-0.362
		C3.701,21.823,3.66,21.661,3.66,21.5v-8.207c0.12,0.109,0.257,0.216,0.387,0.324C4.119,13.677,4.186,13.737,4.262,13.797z M26.66,2
		c13.555,0,23,3.952,23,7.5s-9.445,7.5-23,7.5s-23-3.952-23-7.5S13.106,2,26.66,2z"
      />
    </React.Fragment>
  ),
  viewBox: `0 0 58 58`,
  strokeWidth: "1px",
};

export const so_docker = {
  shape: (
    <React.Fragment>
      <path
        id="docker_1_"
        d="M12.75,22c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75S12.75,21.586,12.75,22z
	 M27.814,17.26h0.215c1.345,0,2.603-0.719,3.281-1.875c0.066-0.112,0.066-0.252,0-0.364c-0.613-1.044-1.664-1.718-2.857-1.848
	c-0.111-1.225-0.83-2.331-1.932-2.939c-0.107-0.061-0.239-0.061-0.349,0c-1.2,0.665-1.946,1.919-1.946,3.273
	c0,0.768,0.241,1.514,0.679,2.138H22.36V12c0-0.199-0.161-0.36-0.36-0.36h-3.64V4c0-0.199-0.161-0.36-0.36-0.36h-4
	c-0.199,0-0.36,0.161-0.36,0.36v3.64H10C9.801,7.64,9.64,7.801,9.64,8v3.64H6c-0.199,0-0.36,0.161-0.36,0.36v3.642H1l0,0
	c-0.096,0-0.187,0.038-0.254,0.105c-0.068,0.067-0.105,0.159-0.105,0.254v0.898c0,2.826,1.148,5.394,3.011,7.277
	c0.033,0.058,0.081,0.105,0.139,0.138c1.929,1.882,4.586,3.046,7.516,3.046C20.144,27.36,27.121,21.447,27.814,17.26z
	 M10.047,23.647c0.19-0.057,0.299-0.257,0.243-0.447s-0.256-0.301-0.448-0.242c-1.425,0.423-3.499,0.67-5.71,0.682
	C2.416,21.889,1.36,19.513,1.36,16.9v-0.539l24.34,0.003l0,0c0.148,0,0.282-0.091,0.336-0.23s0.017-0.296-0.094-0.396
	c-0.633-0.574-0.995-1.387-0.995-2.231c0-1.026,0.531-1.981,1.4-2.54c0.869,0.558,1.4,1.513,1.4,2.54v0.004
	c0,0.196,0.156,0.355,0.353,0.36c1.005,0.022,1.915,0.517,2.474,1.333c-0.57,0.83-1.526,1.337-2.545,1.337h-0.531
	c-0.187,0-0.343,0.143-0.358,0.329c-0.337,3.844-7.287,9.771-15.834,9.771c-2.438,0-4.673-0.863-6.404-2.294
	C6.889,24.287,8.727,24.039,10.047,23.647z M18.36,12.36h3.279v3.28H18.36V12.36z M14.36,12.36h3.28v3.28h-3.28V12.36z M10.36,12.36
	h3.28v3.28h-3.28V12.36z M6.36,12.36h3.28v3.28H6.36V12.36z M14.36,8.36h3.28v3.28h-3.28V8.36z M10.36,8.36h3.28v3.28h-3.28V8.36z
	 M14.36,4.36h3.28v3.28h-3.28V4.36z"
      />
    </React.Fragment>
  ),
  viewBox: `0 0 32 32`,
  strokeWidth: "1px",
};

export const so_cloud_arrow = {
  shape: (
    <React.Fragment>
      <path
        strokeWidth="3px"
        d="M160.93,79.17a16,16,0,0,1-16.05,16H98.57v-12H112a1.72,1.72,0,0,0,1.58-1,1.69,1.69,0,0,0-.38-1.87L91,58a1.71,1.71,0,0,0-2.42,0L66.29,80.28a1.69,1.69,0,0,0-.37,1.87,1.73,1.73,0,0,0,1.6,1H81v24H94.41a1.75,1.75,0,0,1,1.6,1.07,1.7,1.7,0,0,1-.38,1.87L73.38,132.43a1.71,1.71,0,0,1-1.22.5,1.67,1.67,0,0,1-1.2-.5L48.7,110.18a1.72,1.72,0,0,1,1.21-2.94H63.35v-12H26A25,25,0,0,1,9.68,89.15,23.37,23.37,0,0,1,7,86.41,25,25,0,0,1,26,45.16h0c0-.39,0-.81,0-1.22a42.94,42.94,0,0,1,84-12.43,25,25,0,0,1,27.5,4.12,2.23,2.23,0,0,0,.27.26L139,37.06a3.19,3.19,0,0,1,.26.26,25,25,0,0,1,4.82,25.85c.27,0,.55,0,.82,0A16.05,16.05,0,0,1,160.93,79.17Z"
      ></path>
    </React.Fragment>
  ),
  viewBox: "-5 -5 170 140",
};
