/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui";
import React, { useState } from "react";

const Footer = () => {
  const [colorMode, setColorMode] = useState("dark");
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: any) => {
    // setColorMode(`dark`);
  };

  return (
    <Box as="footer" variant="footer">
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
    </Box>
  );
};

export default Footer;
