import * as React from "react";

export const s_ai = {
  shape: (
    <React.Fragment>
      <path
        d="M117.2,102.9c-4.6,11-17.2,16.1-28.2,11.5c-11-4.6-16.1-17.2-11.5-28.2c4.6-11,17.2-16.1,28.2-11.5
	C116.7,79.3,121.8,91.9,117.2,102.9C117.2,102.9,117.2,102.9,117.2,102.9z M213.9,154.6c0,4.3-3.5,7.8-7.8,7.8c0,0,0,0,0,0h-12.7
	v26.8c0,20.8-16.9,37.7-37.7,37.7c0,0,0,0,0,0h-15V254h-102v-81.1C-2.8,140.2-10,80.1,22.6,38.5C40.7,15.5,68.4,2,97.7,2
	c43.4,0,83.4,32.6,91.7,68.4c1.9,8,4,28.8,4,28.8l19.9,52.4C213.7,152.5,213.9,153.5,213.9,154.6L213.9,154.6z M138.2,87.8l12.2-7.6
	l-5.1-12.5l-14.1,3.2c-2.7-3.8-6-7.2-9.8-9.9l3.3-14l-12.5-5.2l-7.7,12.2c-4.6-0.8-9.4-0.8-14-0.1L83,41.5l-12.6,5.1l3.2,14
	c-3.8,2.7-7.2,6-9.9,9.8l-14-3.3l-5.3,12.5l12.2,7.7c-0.8,4.6-0.8,9.4-0.1,14L44.3,109l5.1,12.5l14-3.2c2.7,3.8,6,7.2,9.8,9.9
	l-3.3,14l12.5,5.2l7.7-12.2c4.6,0.8,9.4,0.8,14,0.1l7.6,12.2l12.5-5.1l-3.2-14.1c3.8-2.7,7.2-6,9.9-9.8l14,3.3l5.2-12.5l-12.2-7.7
	C139,97.1,139,92.4,138.2,87.8z"
        strokeWidth="7px"
      />
    </React.Fragment>
  ),
  viewBox: "0 0 216 256",
};

export const s_docker = {
  shape: (
    <React.Fragment>
      <path
        stroke="#066da5"
        stroke-width="38"
        d="M296 226h42m-92 0h42m-91 0h42m-91 0h41m-91 0h42m8-46h41m8 0h42m7 0h42m-42-46h42"
      />
      <path
        fill="#066da5"
        d="m472 228s-18-17-55-11c-4-29-35-46-35-46s-29 35-8 74c-6 3-16 7-31 7H68c-5 19-5 145 133 145 99 0 173-46 208-130 52 4 63-39 63-39"
      />
    </React.Fragment>
  ),
  viewBox: "0 0 512 512",
  strokeWidth: "1px",
};

export const s_bitcoin = {
  shape: (
    <React.Fragment>
      <path
        d="M31.516 19.869c-2.136 8.579-10.819 13.787-19.385 11.652-8.568-2.12-13.787-10.824-11.647-19.381 2.131-8.577 10.819-13.796 19.38-11.661 8.573 2.141 13.792 10.819 11.652 19.396zM23.052 13.719c0.317-2.12-1.303-3.265-3.521-4.036l0.72-2.871-1.756-0.443-0.697 2.813c-0.459-0.12-0.937-0.224-1.417-0.333l0.699-2.839-1.761-0.437-0.719 2.885c-0.38-0.089-0.749-0.177-1.12-0.265l-2.416-0.605-0.469 1.881c0 0 1.303 0.296 1.271 0.312 0.715 0.183 0.844 0.645 0.824 1.021l-1.969 7.895c-0.099 0.22-0.323 0.543-0.817 0.417 0.016 0.025-1.281-0.317-1.281-0.317l-0.88 2.009 2.281 0.568 1.239 0.323-0.724 2.923 1.761 0.432 0.724-2.891c0.479 0.136 0.937 0.255 1.395 0.365l-0.676 2.869 1.76 0.443 0.724-2.921c2.989 0.567 5.24 0.343 6.188-2.365 0.76-2.183-0.043-3.437-1.62-4.26 1.135-0.261 2-1.016 2.239-2.573zM19.036 19.349c-0.536 2.187-4.208 1-5.4 0.703l0.963-3.864c1.193 0.307 5.005 0.895 4.437 3.161zM19.584 13.693c-0.496 1.989-3.548 0.984-4.543 0.733l0.876-3.52c0.989 0.239 4.181 0.697 3.667 2.781z"
        strokeWidth="1.5px"
      />
    </React.Fragment>
  ),
  viewBox: "-3 -3 38 38",
  strokeWidth: "5px",
};

export const s_flutter_color = {
  shape: (
    <React.Fragment>
      <path
        fill="#5DC5F0"
        d="M135.2,3l81.7-0.1l-137,136.9l-35.7,35.5L3.5,134.6L135.2,3z M134.4,125.2c1.1-1.4,3-0.7,4.5-0.9l77.9,0
	l-71,70.9L105,154.7L134.4,125.2z"
      />
      <path
        fill="#46B7EA"
        d="M64.3,195.3l40.7-40.7l40.8,40.6l0.2,0.2l-41,40.6L64.3,195.3L64.3,195.3z"
      />
      <path
        fill="#0B599D"
        d="M105,236.1l41-40.6l70.8,70.9c-26.7,0.1-53.4,0-80,0c-1.5,0.3-2.5-1-3.4-1.9L105,236.1L105,236.1z"
      />
    </React.Fragment>
  ),
  viewBox: "0 0 220 270",
  strokeWidth: "1px",
};
